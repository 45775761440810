<template>
  <main class="view view--settings">
    <section class="header">
      <div class="header__left"></div>

      <div class="header__center">
        <p class="title">{{ $t("Settings") }}</p>
      </div>

      <div class="header__right"></div>
    </section>

    <section class="settings">
      <SelectPopup
        v-model="showLanguages"
        v-on:itemselected="setLanguage"
        :header="'Selecteer een taal'"
        :listsearch="false"
        :items="languages"
      ></SelectPopup>
      <list-item
        v-for="item in listitems"
        :key="item.label"
        :label="item.label"
        :icon="item.icon"
        :clickHandler="item.onClick"
      ></list-item>
    </section>

    <div class="mt-auto p-2 version">
      Prixo <strong>v{{ $config.version }}</strong>
    </div>
  </main>
</template>
<style lang="scss" scoped>
.view--settings {
  display: flex;
  flex-flow: column;
}

.version {
  border-top: 1px solid #dddddd;
  color: #888888;
}
</style>
<script>
import listItem from "./../components/ListItem";
import SelectPopup from "./../components/SelectPopup";

export default {
  components: {
    "list-item": listItem,
    SelectPopup: SelectPopup,
  },
  data: function () {
    return {
      showLanguages: false,
      account: this.$config.get("account"),
    };
  },
  computed: {
    languages() {
      let arr_langs = [];
      let languages = this.$config.languages;
      for (const iso in languages) {
        if (Object.hasOwnProperty.call(languages, iso)) {
          const language = languages[iso];
          arr_langs.push({
            id: iso,
            title: this.$t(language.label),
          });
        }
      }

      return arr_langs;
    },
    listitems() {
      let items = [
        {
          icon: "/img/icon-planner.svg",
          label: this.$t("Planner"),
          onClick: this.gotoPlanner,
        },
        {
          icon: "/img/icon-globe.svg",
          label: this.$t(
            this.$config.languages[this.account.user.language].label
          ),
          onClick: this.chooseLanguage,
        },
        {
          icon: "/img/icon-logout.png",
          label: this.$t("Logout"),
          onClick: this.logout,
        },
      ];

      if (this.$config.get("accounts").length > 1) {
        items.unshift({
          icon: "/img/icon-switch.png",
          label: this.$t("Account"),
          onClick: this.gotoAccounts,
        });
      }

      return items;
    },
  },
  mounted: function () {
    /*if ('Notification' in window && 'serviceWorker' in navigator) {
			this.listitems.unshift(
				{
					icon: '/img/icon-bell.png',
					label: 'Notificaties',
					onClick: this.gotoNotifications
				}
			);
		}*/
  },
  methods: {
    logout: function () {
      this.$config.clear("jwt");
      this.$config.clear("account");
      this.$router.replace({ name: "login" });
    },
    setLanguage: function (item) {
      let old_language = this.account.user.language;
      this.account.user.language = item.id;
      this.$api
        .saveUser(this.account.user)
        .then(() => {
          this.$config.set("account", this.account);
          this.$i18n.locale = item.id;
          this.$moment.locale(this.$config.languages[item.id].momentjs);
          this.showLanguages = false;
        })
        .catch(() => {
          this.account.user.language = old_language;
        });
    },
    chooseLanguage: function () {
      this.showLanguages = true;
    },
    gotoNotifications: function () {
      this.gotoPage("settingsNotifications");
    },
    gotoAccounts: function () {
      this.gotoPage("accounts");
    },
    gotoPlanner: function () {
      window.open("https://app.prixo.io", "_blank");
    },
    gotoPage: function (name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
