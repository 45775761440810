<template>
  <div class="select-popup" :class="value ? 'show' : popupClass">
    <div v-on:click="hide" class="select-popup__background"></div>
    <div class="select-popup__modal">
      <div v-if="header" class="select-popup__title">
        {{ header }}
        <button @click="hide" class="btn btn-close"></button>
      </div>
      <div v-if="listsearch" class="select-popup__search">
        <input
          v-model="searchText"
          type="search"
          ref="searchText"
          class="select-popup__search-input"
          :placeholder="$t('Search...')"
        />
      </div>
      <div
        v-if="itemsToShow"
        ref="itemlist"
        class="select-popup__list"
        @scroll="infiniteScroll"
      >
        <div
          v-on:click="itemSelected(item)"
          class="select-popup__item"
          v-for="item in itemsToShow"
          :key="item.id"
        >
          {{ item[valueText] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectPopup",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    header: null,
    items: null,
    listsearch: {
      type: Boolean,
      default: false,
    },
    valueText: {
      type: String,
      default: "title",
    },
  },
  data: function () {
    return {
      popupClass: null,
      searchText: "",
      listitems: [],
      itemsToShow: [],
    };
  },
  created() {
    this.setListItems();
  },
  watch: {
    items: function () {
      this.setListItems();
    },
    searchText: function () {
      this.setListItems();
      this.$refs.itemlist.scrollTop = 0;
    },
  },
  methods: {
    itemSelected: function (item) {
      this.$emit("itemselected", item);
    },
    hide: function () {
      this.popupClass = null;
      this.$emit("input", false);
    },
    show: function () {
      this.popupClass = "show";

      this.$nextTick(function () {
        this.$refs.searchText.focus();
      });
    },
    infiniteScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        this.loadMorePosts();
      }
    },
    setListItems() {
      this.listitems = [];

      if (this.items) {
        for (let i = 0; i < this.items.length; i++) {
          const element = this.items[i];
          if (
            this.searchText == "" ||
            (element[this.valueText] &&
              element[this.valueText]
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1)
          ) {
            this.listitems.push(element);
          }
        }
      }

      this.resetItemsToShow();
    },
    resetItemsToShow() {
      this.itemsToShow = [];
      this.loadMorePosts();
    },
    loadMorePosts() {
      var from = this.itemsToShow.length;
      var to = from < 100 ? 100 : from + 100;
      if (to > this.listitems.length) to = this.listitems.length;

      for (let i = from; i < to; i++) {
        this.itemsToShow.push(this.listitems[i]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";

@keyframes select-popup__background-fade {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes select-popup__slide-fwd-center {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0, 0);
    transform: translate(-50%, -50%) scale(0, 0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1, 1);
    transform: translate(-50%, -50%) scale(1, 1);
  }
}
@keyframes select-popup__slide-fwd-center {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0, 0);
    transform: translate(-50%, -50%) scale(0, 0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1, 1);
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

.select-popup {
  display: none;
  &.show {
    display: block;

    .select-popup__background {
      animation-name: select-popup__background-fade;
      animation-duration: 0.2s;
    }

    .select-popup__modal {
      -webkit-animation: select-popup__slide-fwd-center 0.25s
        cubic-bezier(0.25, 0.46, 0.25, 0.94) both;
      animation: select-popup__slide-fwd-center 0.25s
        cubic-bezier(0.25, 0.46, 0.25, 0.94) both;
    }
  }
}

.select-popup__background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.select-popup__modal {
  position: fixed;
  max-height: 90vh;
  width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1, 1);
  background: #ffffff;
  text-align: left;
  z-index: 6;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  .select-popup__title {
    padding: 10px;
    text-align: center;
    background-color: $primary;
    line-height: 30px;
    font-size: 1.15rem;
    text-transform: uppercase;
  }

  .btn {
    width: 15px;
    height: 15px;
    background-size: 10px auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    border-radius: 50%;
    display: block;

    &:hover,
    &:active {
      background-color: rgba($secondary, 0.1);
    }
  }
  .btn-close {
    float: right;
    background-image: url("/img/icon-close.png");
  }

  .select-popup__list {
    overflow-y: scroll;
    border-top: 5px solid #333;

    .select-popup__item {
      padding: 15px 10px;
      border-bottom: 1px dashed rgba($secondary, 0.5);
      cursor: pointer;
      font-size: 1.15rem;
      line-height: 1.1;

      &:hover {
        background-color: rgba($secondary, 0.2);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.select-popup__search {
  padding: 10px;
  background-color: $primary;
  .select-popup__search-input {
    width: 100%;
    border: 1px solid #dddddd;
    padding: 10px;
  }
}
</style>
